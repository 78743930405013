import payload_plugin_gEnkeIDQ7F from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.5.0_firebase@10.13.2_vuefire@3.2.0/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_mos7bvjMPI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EDhGQP0CWa from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5iR1mO2r5Z from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_6QauGz4sL8 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.18_vite@5.4.6_vue@3.5.6/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_jHugz1CUjR from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PCWjIoB7gl from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_SwAPeKWSnl from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_aDv9OYHrwy from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _64nuxt_scripts_D34jXE4xNh from "/opt/buildhome/repo/.nuxt/modules/@nuxt-scripts.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_1Gxs6JAiyC from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_Sr3I3NXw6q from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.5.0_firebase@10.13.2_vuefire@3.2.0/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/opt/buildhome/repo/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/opt/buildhome/repo/.nuxt/vuefire-plugin.mjs";
import titles_KAiy6ziJCx from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_h3@1.12.0_vite@5.4.6_vue@3.5.6/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_UQAhRwnvX7 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_vite@5.4.6_vue@3.5.6/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_hB4k6WnCPo from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_vite@5.4.6_vue@3.5.6/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import slideovers_HhIa0B5DEN from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.5_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_VLldwFDcjP from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.5_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_4akJKDf4vD from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.5_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_1rze2VsG7c from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_rg69P2x1Ni from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.5.1_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_iuBjwcc6GA from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.2_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_Lq9NBUckMh from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.2_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_zCUUaiHs2X from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.2_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import paddleJS_client_6OSkSWlVfm from "/opt/buildhome/repo/plugins/paddleJS.client.ts";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/plugins/sentry.client.ts";
import defaults_O7oB9vi21F from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_h3@1.12.0_vite@5.4.6_vue@3.5.6/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  payload_plugin_gEnkeIDQ7F,
  revive_payload_client_mos7bvjMPI,
  unhead_EDhGQP0CWa,
  router_5iR1mO2r5Z,
  _0_siteConfig_6QauGz4sL8,
  payload_client_jHugz1CUjR,
  navigation_repaint_client_PCWjIoB7gl,
  check_outdated_build_client_SwAPeKWSnl,
  chunk_reload_client_aDv9OYHrwy,
  _64nuxt_scripts_D34jXE4xNh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_1Gxs6JAiyC,
  plugin_client_Sr3I3NXw6q,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  titles_KAiy6ziJCx,
  siteConfig_UQAhRwnvX7,
  inferSeoMetaPlugin_hB4k6WnCPo,
  slideovers_HhIa0B5DEN,
  modals_VLldwFDcjP,
  colors_4akJKDf4vD,
  plugin_client_1rze2VsG7c,
  plugin_rg69P2x1Ni,
  scrollbars_client_iuBjwcc6GA,
  presets_Lq9NBUckMh,
  variables_zCUUaiHs2X,
  paddleJS_client_6OSkSWlVfm,
  sentry_client_shVUlIjFLk,
  defaults_O7oB9vi21F
]